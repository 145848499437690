<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7204 18.6263C15.2345 18.708 14.7427 18.7494 14.25 18.75C12.7447 18.7674 11.2637 18.37 9.96938 17.6014C10.0819 17.6014 10.1944 17.625 10.3125 17.625C14.9925 17.625 18.8895 14.6415 19.7096 10.7186C20.5135 11.5 20.9771 12.5667 21 13.6875C20.9836 14.734 20.5777 15.7368 19.8615 16.5H19.875V21L15.7204 18.6263ZM4.125 12.4747C3.40351 11.53 3.00867 10.3762 3 9.1875C3 5.76975 6.27375 3 10.3125 3C14.3512 3 17.625 5.76975 17.625 9.1875C17.625 12.6053 14.3512 15.375 10.3125 15.375C9.23304 15.3764 8.16348 15.1693 7.1625 14.7653L4.125 16.5V12.4747Z"
      fill="#343C44"
    />
  </svg>
</template>

<script>
export default {};
</script>