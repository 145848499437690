<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 13.25C13.1125 13.25 14.2001 12.9201 15.1251 12.302C16.0501 11.6839 16.7711 10.8054 17.1968 9.7776C17.6226 8.74976 17.734 7.61876 17.5169 6.52762C17.2999 5.43648 16.7641 4.4342 15.9775 3.64753C15.1908 2.86086 14.1885 2.32513 13.0974 2.10809C12.0062 1.89104 10.8752 2.00244 9.84741 2.42818C8.81957 2.85392 7.94107 3.57489 7.32298 4.49992C6.7049 5.42495 6.375 6.51248 6.375 7.625C6.37544 9.11671 6.96822 10.5472 8.02301 11.602C9.07781 12.6568 10.5083 13.2496 12 13.25V13.25ZM17 14.5H14.8475C13.9547 14.9117 12.9832 15.1249 12 15.1249C11.0168 15.1249 10.0453 14.9117 9.1525 14.5H7C5.67392 14.5 4.40215 15.0268 3.46447 15.9645C2.52678 16.9021 2 18.1739 2 19.5L2 20.125C2.00022 20.6222 2.19784 21.099 2.54942 21.4506C2.901 21.8022 3.37779 21.9998 3.875 22H20.125C20.6222 21.9998 21.099 21.8022 21.4506 21.4506C21.8022 21.099 21.9998 20.6222 22 20.125V19.5C22 18.1739 21.4732 16.9021 20.5355 15.9645C19.5979 15.0268 18.3261 14.5 17 14.5Z"
      fill="#343C44"
    />
  </svg>
</template>

<script>
export default {};
</script>