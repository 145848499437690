<template>
  <header>
    <div
      class="
        d-flex
        flex-wrap
        align-items-center
        justify-content-center justify-content-lg-start
      "
    >
      <router-link :to="{ name: 'dashboard' }" class="navbar-brand">
        <img src="@/assets/img/logo-inverse.png" alt="Frontliner" />
      </router-link> 

      <form
        class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-auto flex-grow-1"
        @submit="buscarPaquetes"
      >
        <div class="d-flex">
          <label class="form-label">
            <search-icon></search-icon>
          </label>
          <input
            type="search"
            class="form-control"
            placeholder="Buscar por tracking"
            aria-label="Buscar por tracking"
            v-model="filtro"
          />
        </div>
      </form>

      <ul
        class="
          nav
          col-12 col-lg-auto
          mb-2
          me-lg-3
          justify-content-center
          mb-md-0
        "
      >
        <li>
          <a href="#" class="nav-link px-2 link-dark">
            <chat-icon></chat-icon>
          </a>
        </li>
        <li>
          <a href="#" class="nav-link px-2 link-dark">
            <alert-icon></alert-icon>
          </a>
        </li>
      </ul>

      <div class="dropdown text-end">
        <a
          href="#"
          class="d-block link-dark text-decoration-none pe-3"
          id="dropdownHeader"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <user-icon></user-icon>
        </a>
        <ul class="dropdown-menu text-small" aria-labelledby="dropdownHeader">
          <li>
            <a class="dropdown-item" href="#">{{
              $t("changePassword.action")
            }}</a>
          </li>
          <li><hr class="dropdown-divider" /></li>
          <li>
            <a class="dropdown-item" href="javascript:void(0)" @click="logout">
              {{ $t("logout") }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<style scoped>
/* header {
  height: var(--navbar-height);
  padding: 0;
} */
.navbar-brand {
  width: var(--sidebar-width);
  text-align: center;
  background-color: var(--primary-color--600);
  height: 100%;
  padding: 0.5rem 0;
}
.navbar-brand img {
  max-width: 80%;
  max-height: calc(var(--navbar-height) - 0.5rem);
}
.form-control,
.form-control:focus,
.form-control:active {
  border: none;
  outline: none;
  box-shadow: none;
}
</style>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import ChatIcon from "@/components/icons/ChatIcon.vue";
import AlertIcon from "@/components/icons/AlertIcon.vue";
import UserIcon from "@/components/icons/UserIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";

export default {
  name: "NavHeader",
  components: {
    ChatIcon,
    AlertIcon,
    UserIcon,
    SearchIcon,
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      filtro: null,
    };
  },
  methods: {
    ...mapActions({
      signOut: "auth/logout",
    }),
    async logout() {
      await axios.post("/logout").then(({ data }) => {
        console.log(data);
        this.signOut();
        this.$router.push({ name: "login" });
      });
    },
    buscarPaquetes(event) {
      event.preventDefault();
      if (this.filtro) {
        this.$router.push({
          name: "paquetesBuscados",
          query: { filtro: this.filtro },
        });
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(/* to, from */) {
        this.filtro = this.$route.query.filtro;
      },
    },
  },
};
</script>