<template>
  <div>
    <NavHeader />
    <main>
      <NavSidebar />
      <router-view class="main-view mt-3"></router-view>
    </main>
  </div>
</template>

<style scoped>
main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: calc(100vh - var(--navbar-height));
  overflow-x: auto;
  overflow-y: hidden;
  background: var(--primary-color--100);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) inset;
}
.main-view {
  overflow: auto;
}
</style>

<script>
import NavHeader from "@/components/NavHeader.vue";
import NavSidebar from "@/components/NavSidebar.vue";

export default {
  name: "dashboard-layout",
  components: {
    NavHeader,
    NavSidebar,
  },
};
</script>