<template>
  <div class="d-flex flex-column flex-shrink-0 py-3 ps-1 text-white f-sidebar">
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item">
        <router-link
          :to="{ name: 'dashboard' }"
          class="nav-link text-white px-3"
        >
          {{ $t("home") }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'paquetesRetirados' }"
          class="nav-link text-white px-3"
        >
          {{ $t("paquetesRetirados") }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.f-sidebar {
  width: var(--sidebar-width);
  background-color: var(--primary-color--500);
}
.router-link-active {
  background-color: var(--primary-color--400);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>

<script>
export default {
  data() {
    return {
      busqueda: null,
    };
  },
};
</script>
